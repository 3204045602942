export const COUNTRY_CODE_ICON_MAP = {
  cs: 'czech-republic',
  en: 'england',
  de: 'germany',
  ru: 'russia'
};

export const ICON_TYPE = {
  SVG: 'svg',
  FONT: 'font'
};
