import React from 'react';

import { CustomFC } from '@monorepo/type';

import { SvgIconProps } from '../../types';

import svgIcons from './icons';

import styles from './index.module.css';

const SvgIcon: CustomFC<SvgIconProps> = ({
  name,
  className = '',
  title = '',
  defaultIcon = '',
  ...props
}) => {
  const Component = svgIcons[name] || svgIcons[defaultIcon];

  if (!Component) {
    return null;
  }

  return (
    <Component
      title={title}
      className={`${styles.svg} ${className}`}
      {...props}
    />
  );
};

export default SvgIcon;
